import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-aengus-blog/src/templates/default.js";
import BlogPosts from "gatsby-theme-aengus-blog/src/components/BlogPosts.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Hi, I'm Aengus`}</h1>
    <p>{`I'm a software developer, previously employed working on Android at Facebook, and currently taking a sabbatical to work on creating my own things and teaching what I know and what I learn.`}</p>
    <p>{`I'm still new to all this, but I have started a series on learning how to program using Kotlin. If you are interested you can check that out below. `}</p>
    <p>{`And if you want to get in touch, you can find me in a couple of places:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://twitter.com/aengusmcmillin"
        }}>{`@aengusmcmillin`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://github.com/aengusmcmillin"
        }}>{`github.com/aengusmcmillin`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "mailto:aengusmcmillin@gmail.com"
        }}>{`aengusmcmillin@gmail.com`}</a></li>
    </ul>
    <h2>{`Posts`}</h2>
    <BlogPosts mdxType="BlogPosts" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      